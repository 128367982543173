import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Seo, Layout, OtherPageWrapper, SeoPage } from "components"

const Page = () => (
  <Layout>
    <Seo
      title="Best Eggs Benedict in Newhall"
      description="A great day starts with a great breakfast. Our take on breakfast classics are something you won’t find everywhere. Come try our eggs benedict and fall in love with our hollandaise sauce. "
      keywords="best eggs Benedict, best eggs Benedict near me, best eggs Benedict in Newhall, best eggs benedict in Santa Clarita"
    />
    <OtherPageWrapper title="Best Eggs Benedict in Newhall" />
    <SeoPage
      title="Best Eggs Benedict in Newhall"
      name="Rene Paz"
      review="Absolutely AMAZING. Everything we have had here is delicious. I love the Eggs Benedict and one is of the best I have ever had. The coffee flavorings are awesome and the homemade jams are really great. Can't say enough about this place you definitely have to try it."
    >
      <StaticImage
        imgStyle={{ borderRadius: "20px" }}
        src="../images/best-eggs.jpeg"
        alt="Best Eggs Benedict in Newhall"
        placeholder="blurred"
      />
    </SeoPage>
  </Layout>
)

export default Page
